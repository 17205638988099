import React, { useState } from 'react';
import './BecomeTutor.css'; // Import CSS file for styling

const BecomeTutor = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    qualifications: '',
    idAttachment: null,
    qualificationsAttachment: null,
    photo: null,
    agreeToTerms: false,
  });

  const handleChange = (e) => {
    const { name, value, type, files, checked } = e.target;
    const newValue = type === 'file' ? files[0] : type === 'checkbox' ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.agreeToTerms) {
      alert('Please agree to the terms and conditions.');
      return;
    }
    // You can handle form submission logic here, such as sending data to the server
    console.log('Form submitted:', formData);
  };

  return (
    <div className="become-tutor-container">
      <h1 className="form-title">Become a Tutor</h1>
      <p className="form-description">If you're passionate about teaching and want to join our platform as a tutor, fill out the form below:</p>
      <form onSubmit={handleSubmit} className="become-tutor-form">
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="subject">Subject:</label>
          <input type="text" id="subject" name="subject" value={formData.subject} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="qualifications">Qualifications:</label>
          <textarea id="qualifications" name="qualifications" value={formData.qualifications} onChange={handleChange} required />
        </div>
         <div className="form-group">
          <label htmlFor="photo">User Photo:</label>
          <input type="file" id="photo" name="photo" onChange={handleChange} accept="image/*" required />
        </div>
        <div className="form-group">
          <label htmlFor="idAttachment">ID Attachment:</label>
          <input type="file" id="idAttachment" name="idAttachment" onChange={handleChange} accept=".pdf,.doc,.docx,.jpg,.jpeg,.png" required />
        </div>
        <div className="form-group">
          <label htmlFor="qualificationsAttachment">Qualifications Attachment:</label>
          <input type="file" id="qualificationsAttachment" name="qualificationsAttachment" onChange={handleChange} accept=".pdf,.doc,.docx,.jpg,.jpeg,.png" required />
        </div>
       
        <div className="form-group">
          <label className="terms-label" htmlFor="agreeToTerms">
            I agree to the <a href="/terms-and-conditions" target="_blank" className="terms-link">terms and conditions</a>.
          </label>
          <div className="checkbox-container">
            <input type="checkbox" id="agreeToTerms" name="agreeToTerms" checked={formData.agreeToTerms} onChange={handleChange} required />
          </div>
        </div>
        <button type="submit" className="submit-btn">Submit</button>
      </form>
    </div>
  );
};

export default BecomeTutor;
