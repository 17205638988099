import React, { useState } from "react";
import "./MalatjiProfile.css"; 
import RadoImage from "./Rado.jpg";
import SubscriptionModal from "./Subscription";

export default function MalatjiProfile() {
  const [showSubscription, setShowSubscription] = useState(false);

  const handleSubscriptionClick = () => {
    setShowSubscription(true);
  };

  const handleClose = () => {
    setShowSubscription(false);
  };

  return (
    <div className="profile-container">
      <div className="profile-header">
        <img src={RadoImage} alt="Profile" className="profile-image" />
      </div>
      <div className="profile-info">
        <h1 className="profile-name">John Doe</h1>
        <p className="profile-role">Math Tutor</p>
        <div className="profile-bio">
          <p>
            Hi, I'm John Doe, a passionate math tutor with over 10 years of experience. I specialize in helping students improve their math skills and achieve their academic goals.
          </p>
        </div>
        <button className="subscribe-button" onClick={handleSubscriptionClick}>
          Subscribe to my classes
        </button>
      </div>
      <div className="video-container">
        <video className="profile-video" controls>
          <source
            src="https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>

      {showSubscription && <SubscriptionModal onClose={handleClose} />}
    </div>
  );
}
