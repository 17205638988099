import React from "react";
import "./Student.css";
import RadoImage from "./Rado.jpg"; // Import the image directly

export const Button = ({ variant, size, children }) => (
  <button className={`btn btn-${variant} btn-${size}`}>{children}</button>
);

const Student = () => {
  return (
    <div className="student-container">
      <div className="student-header">
        <div className="student-info">
          <img src={RadoImage} alt="Student" className="student-avatar" /> {/* Use imported image */}
          <div className="student-details">
            <h2 className="student-name">John Smith</h2>
            <div className="student-grade">Grade 10</div>
          </div>
        </div>
        <div className="student-premium">Premium</div>
      </div>
      <div className="student-classes">
        <h3>Upcoming Classes</h3>
        <div className="class-item">
          <div className="class-info">
            <div className="class-name">Math</div>
            <div className="class-time">Monday, 9:00 AM - 10:30 AM</div>
          </div>
          <Button variant="outline" size="sm">Join</Button>
        </div>
        <div className="class-item">
          <div className="class-info">
            <div className="class-name">English</div>
            <div className="class-time">Tuesday, 2:00 PM - 3:30 PM</div>
          </div>
          <Button variant="outline" size="sm">Join</Button>
        </div>
        <div className="class-item">
          <div className="class-info">
            <div className="class-name">History</div>
            <div className="class-time">Friday, 11:00 AM - 12:30 PM</div>
          </div>
          <Button variant="outline" size="sm">Join</Button>
        </div>
      </div>
    </div>
  );
};

export default Student;
