import React from 'react';
import './MyExtraBoard.css'; // Import CSS file for styling

function MyExtraBoard() {
  // Sample data for upcoming classes and announcements
  const upcomingClasses = [
    { id: 1, subject: 'Mathematics', time: '9:00 AM - 10:30 AM', teacher: 'Mr. Smith', link: 'https://example.com/math' },
    { id: 2, subject: 'Science', time: '11:00 AM - 12:30 PM', teacher: 'Ms. Johnson', link: 'https://example.com/science' },
  ];

  const announcements = [
    { id: 1, title: 'New Class Schedule', content: 'Check the updated class schedule on the website.' },
    { id: 2, title: 'Exam Preparation Tips', content: 'Tips for effective exam preparation are now available.' },
  ];

  return (
   <div className="content-container">
      <div className="content-background" />
      <div className="my-extra-board">
       <h1 className="heading">Welcome to Extra Board!</h1>
        
        
        <section className="section">
          <h2 className="section-title">Upcoming Classes</h2>
          <div className="card-container">
            {upcomingClasses.map((classItem) => (
              <div key={classItem.id} className="card">
                <h3>{classItem.subject}</h3>
                <p><strong>Instructor:</strong> {classItem.teacher}</p>
                <p><strong>Time:</strong> {classItem.time}</p>
                <a href={classItem.link} className="class-link">Join Class</a>
              </div>
            ))}
          </div>
        </section>

        <section className="section">
          <h2 className="section-title">Announcements</h2>
          <div className="card-container">
            {announcements.map((announcement) => (
              <div key={announcement.id} className="card">
                <h3>{announcement.title}</h3>
                <p>{announcement.content}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Add more sections as needed */}
      </div>
    </div>
  );
}

export default MyExtraBoard;
