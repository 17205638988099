import React from 'react';
import './TermsAndConditions.css'; // Import CSS file for styling

const TermsAndConditions = () => {
  return (
    <div>
      <h1 className="title">Terms and Conditions</h1>
      <p className="subtitle">Please read these terms and conditions carefully before uploading your ID and qualification attachments.</p>
      
      <div className="section">
        <h2 className="section-title">ID Attachment:</h2>
        <p className="centered">By uploading your ID attachment, you agree that:</p>
        <ul>
          <li>The provided ID document is valid and belongs to you.</li>
          <li>You authorize Extra Classes to use your ID document for verification purposes.</li>
          <li>Extra Classes may retain a copy of your ID document for record-keeping and compliance purposes.</li>
          <li>You are solely responsible for the accuracy and legality of the information provided in the ID document.</li>
          <li>Extra Classes reserves the right to reject or remove any ID document that does not meet our verification standards.</li>
        </ul>
      </div>
      
      <div className="section">
        <h2 className="section-title">Qualifications Attachment:</h2>
        <p className="centered">By uploading your qualifications attachment, you agree that:</p>
        <ul>
          <li>The provided qualifications document accurately reflects your educational and professional qualifications.</li>
          <li>You authorize Extra Classes to use your qualifications document for verification purposes.</li>
          <li>Extra Classes may retain a copy of your qualifications document for record-keeping and compliance purposes.</li>
          <li>You are solely responsible for the authenticity and validity of the qualifications presented in the document.</li>
          <li>Extra Classes reserves the right to verify the authenticity of the qualifications provided and may request additional documentation if necessary.</li>
        </ul>
      </div>
      
      <p className="centered">By uploading your ID and qualifications attachments, you acknowledge and agree to abide by these terms and conditions.</p>
    </div>
  );
};

export default TermsAndConditions;
