import React, { useState } from 'react';
import './ContactUs.css';
import emailIcon from './email-icon.png';
import phoneIcon from './phone-icon.png';

const ContactUs = ({ onClose }) => {
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    // Implement form submission logic here
    // (e.g., send data to your server or API)
    console.log('Form submitted:', { name, phoneNumber, email, message });
    setFormSubmitted(true);

    // Clear form after successful submission (optional)
    setName('');
    setPhoneNumber('');
    setEmail('');
    setMessage('');
  };

  return (
    <div className="contact-overlay" onClick={onClose}>
      <div className="contact-container" onClick={(e) => e.stopPropagation()}>
        <h2>Contact Us</h2>

        {formSubmitted ? (
          <p className="success-message">Thank you for your message! We'll get back to you soon.</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Your Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone Number (Optional):</label>
              <input
                type="tel" // Use "tel" for phone number input (optional)
                id="phone"
                name="phone"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Your Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>
            </div>
            <button type="submit">Send Message</button>
          </form>
        )}

        
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default ContactUs;
