// Careers.js

import React from 'react';
import './Careers.css';

const Careers = ({ onClose }) => {
  return (
    <div className="careers-overlay" onClick={onClose}>
      <div className="careers-container" onClick={(e) => e.stopPropagation()}>
        <h2>Careers at ExtraClassesHub</h2>
        <p>
          Join our team and become part of a dynamic and innovative educational
          platform! We are always looking for talented individuals who are
          passionate about education and dedicated to making a difference in
          the lives of students.
        </p>
        <p>
          Explore our current job openings and take the next step in your
          career with ExtraClassesHub.
        </p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default Careers;
