import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Profile.css';

export default function Profile() {
  const [isSignUp, setIsSignUp] = useState(false);
  const navigate = useNavigate();

  const toggleForm = () => {
    setIsSignUp(prev => !prev);
  };

  const handleSignIn = () => {
    // Perform sign-in logic here
    navigate('/student'); // Redirect to Student.js
  };

  const handleSignUp = () => {
    // Perform sign-up logic here
    navigate('/student'); // Redirect to Student.js
  };

  return (
    <div className="profile-container">
      <div className={`form-wrapper ${isSignUp ? 'flip sign-up-active' : 'sign-in-active'}`}>
        {/* Sign-In Form */}
        <div className={`form sign-in-form ${isSignUp ? 'hidden' : 'visible'}`}>
          <h1>Welcome back!</h1>
          <p>Enter your credentials to access your account.</p>
          <div className="form-group">
            <input type="email" placeholder="Email" />
          </div>
          <div className="form-group">
            <input type="password" placeholder="Password" />
          </div>
          <button className="button button-solid" onClick={handleSignIn}>Sign In</button>
          <div className="social-buttons">
            <button className="social-button">
              <i className="fab fa-facebook-f icon"></i>
              Sign in with Facebook
            </button>
            <button className="social-button">
              <i className="fab fa-google icon"></i>
              Sign in with Google
            </button>
          </div>
          <p>
            Don't have an account? <span onClick={toggleForm} className="toggle-link">Sign Up</span>
          </p>
        </div>

        {/* Sign-Up Form */}
        <div className={`form sign-up-form ${isSignUp ? 'visible' : 'hidden'}`}>
          <h1>Create Account</h1>
          <div className="form-group">
            <input type="text" placeholder="First Name" />
          </div>
          <div className="form-group">
            <input type="text" placeholder="Last Name" />
          </div>
          <div className="form-group">
            <input type="tel" placeholder="Phone Number" />
          </div>
          <div className="form-group">
            <input type="email" placeholder="Email" />
          </div>
          <div className="form-group">
            <input type="password" placeholder="Password" />
          </div>
          <div className="form-group">
            <input type="password" placeholder="Confirm Password" />
          </div>
          <button className="button button-solid" onClick={handleSignUp}>Sign Up</button>
          <div className="social-buttons">
            <button className="social-button">
              <i className="fab fa-facebook-f icon"></i>
              Sign Up with Facebook
            </button>
            <button className="social-button">
              <i className="fab fa-google icon"></i>
              Sign Up with Google
            </button>
          </div>
          <p>
            Already have an account? <span onClick={toggleForm} className="toggle-link">Sign In</span>
          </p>
        </div>
      </div>
    </div>
  );
}
