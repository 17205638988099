import React from 'react';
import './DotLoader.css';

const DotLoader = () => {
  const numDots = 5; // Adjust the number of dots as needed
  const dots = Array.from({ length: numDots }, (_, index) => (
    <div key={index} className="dot-loader" style={{ animationDelay: `${index * 0.1}s` }}></div>
  ));

  return (
    <div className="loader-overlay">
      <div className="dot-loader-container">
        {dots}
      </div>
    </div>
  );
};

export default DotLoader;
