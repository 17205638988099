import React from "react";
import "./Subscription.css"; // Custom CSS for the modal

export default function Subscription({ onClose }) {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3 className="modal-title">Tutor Classes Subscription</h3>
        <p className="modal-description">Choose your preferred payment method to subscribe.</p>
        <form className="modal-form">
          <div className="modal-form-group">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" placeholder="Enter your name" />
          </div>
          <div className="modal-form-group">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" placeholder="Enter your email" />
          </div>
          <div className="modal-form-group">
            <label htmlFor="plan">Subscription Plan:</label>
            <select id="plan">
              <option value="basic">Basic - R9/month</option>
              <option value="pro">Pro - R19/month</option>
              <option value="premium">Premium - R29/month</option>
            </select>
          </div>
          <div className="modal-form-group">
            <label htmlFor="payment">Payment Method:</label>
            <select id="payment">
              <option value="payfast">PayFast</option>
              <option value="creditcard">Credit Card</option>
              <option value="vodapay">VodaPay</option>
              <option value="mtnpay">MTN Pay</option>
            </select>
          </div>
          <button type="submit" className="modal-submit">Subscribe</button>
        </form>
        <button className="modal-close" onClick={onClose}>Close</button>
      </div>
    </div>
  );
}
