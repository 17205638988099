// SupportUs.js
import React from 'react';
import './SupportUs.css'; // Import CSS file
import donationImage from './donation_image.jpg'; // Import the image

const SupportUs = () => {
  return (
    <div className="donation-container">
      <h2>Support Us</h2>
      <img src={donationImage} alt="Donate Now" className="donation-image" />
      <p>
        Thank you for considering supporting us! Your donations help us maintain and improve our services.
      </p>
      <p>
        To make a donation, please use one of the following methods:
      </p>
      <ul className="donation-methods">
        <li>
          PayPal: <strong>extraclasseshub@gmail.com</strong>
        </li>
        <li>
          Bank Transfer: Account number <strong>XXXX-XXXX-XXXX</strong>, Bank Name
        </li>
        {/* Add more donation methods as needed */}
      </ul>
    </div>
  );
};

export default SupportUs;
