import React from 'react';

const DashboardPage = () => {
  return (
    <div>
      <h1>Dashboard Page</h1>
      <p>Welcome to your dashboard!</p>
      {/* Add your dashboard content here */}
    </div>
  );
};

export default DashboardPage;
