import React from 'react';
import './FAQ.css'; // Import CSS file for styling

function FAQ() {
  return (
    <div className="faq-container">
      <h1 className="faq-title">Frequently Asked Questions</h1>

      <div className="faq-section">
        <h2 className="faq-section-title">App Overview</h2>
        <p>Extra Classes is a platform designed to connect matric learners with qualified tutors for extra classes in various subjects...</p>
      </div>

      <div className="faq-section">
        <h2 className="faq-section-title">Key Features</h2>

        <div className="faq-subsection">
          <h3>User Registration:</h3>
          <ul>
            <li>Tutors and learners can create accounts with email or social media authentication.</li>
            <li>Collect necessary information such as name, email, password, and profile details.</li>
          </ul>
        </div>

        <div className="faq-subsection">
          <h3>Tutor Profiles:</h3>
          <ul>
            <li>Tutors can create detailed profiles showcasing their qualifications, teaching experience, subjects taught, and availability.</li>
            <li>Include a profile picture and a brief bio to personalize the profile.</li>
            <li>Option to add SACE certificates as proof of qualification and accreditation.</li>
          </ul>
        </div>

        {/* Repeat similar structure for other key features */}
      </div>

      
    </div>
  );
}

export default FAQ;
