// OurTeam.js

import React from 'react';
import './OurTeam.css';

const OurTeam = ({ onClose }) => {
  return (
    <div className="team-overlay" onClick={onClose}>
      <div className="team-container" onClick={(e) => e.stopPropagation()}>
        <h2>Meet Our Team</h2>
        <p>
          At ExtraClassesHub, we have a dedicated team of professionals
          committed to providing the best educational experience for our
          learners. Our team consists of experienced educators, designers,
          developers, and support staff, all working together to ensure that
          our platform meets the needs of our users.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non diam
          non leo dignissim ultrices. Phasellus efficitur aliquet odio, id
          congue est molestie nec. Sed vitae ex quis magna ultricies volutpat.
          Nulla ultricies purus vel ipsum tincidunt, ut sodales turpis
          dignissim. Vestibulum ante ipsum primis in faucibus orci luctus et
          ultrices posuere cubilia Curae; Proin nec dolor est.
        </p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default OurTeam;
