// About.js

import React from 'react';
import './About.css';

const About = ({ onClose }) => {
  return (
    <div className="about-overlay" onClick={onClose}>
      <div className="about-container" onClick={(e) => e.stopPropagation()}>
        <h2>About ExtraClassesHub</h2>
        <p>
          ExtraClassesHub is a platform designed to connect matric learners
          with qualified tutors for extra classes in various subjects. Tutors
          can create accounts, set their prices, and schedule classes, while
          learners can browse tutor profiles, subscribe to monthly plans for
          specific subjects, and access additional learning resources.
        </p>
        <p>
          Our mission is to provide accessible and personalised educational
          support to matric learners, helping them achieve academic success and
          reach their full potential.
        </p>
        <p>
          At ExtraClassesHub, we are committed to fostering a supportive
          learning environment where learners can connect with experienced
          tutors, engage in interactive classes, and access high-quality
          learning resources.
        </p>
        <p>
          Join ExtraClassesHub today and take the next step towards academic
          excellence!
        </p>



        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default About;





