import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import './App.css';
import myLogo from './logo.svg';
import SupportUs from './SupportUs';
import Don from './Don.jpg';
import Globes from './Globes.jpg';
import Rado from './Rado.jpg';
import ModupiImage from './Modupi.jpg'; // Rename to avoid confusion
import BecomeTutor from './BecomeTutor'; // Import the BecomeTutor component
import Mawuena from './Mawuena.jpg';
import Stellvish from './Stellvish.jpg';
import Kuda from './Kuda.jpg'; // Keep this import
import Mmaphosa from './Mmaphosa.jpg';
import TermsAndConditions from './TermsAndConditions'; // Import the TermsAndConditions component
import appStoreLogo from './en-us.svg';
import playStoreLogo from './play-store-logo.png';
import LoginPage from './LoginPage';
import DashboardPage from './DashboardPage';
import LogoutPage from './LogoutPage';
import About from './About'; 
import ContactUs from './ContactUs';
import MyExtraBoard from './MyExtraBoard'; // Update import statement
import FAQ from './FAQ';
import Profile from './Profile';
import TermsOfUse from './TermsOfUse';
import OurTeam from './OurTeam';
import Careers from './Careers';
import DotLoader from './DotLoader'; // Import the DotLoader component here
import UnderConstructionPopup from './UnderConstructionPopup'; // Import the UnderConstructionPopup component
import MalatjiProfile from './MalatjiProfile'; // Import the MalatjiProfile component
import Mamabolo from './Mamabolo'; // Import Mamabolo profile component
import Modupi from './Modupi'; // Import Modupi profile component
import Modiba from './Modiba'; // Import Modiba profile component
import Emanuel from './Emanuel'; // Import Emanuel profile component
import Phasha from './Phasha'; // Import Phasha profile component
import Monyela from './Monyela'; // Import Monyela profile component
import Student from './Student'; // Import your Student component
import axios from 'axios';

function Home() {
  const [searchQuery, setSearchQuery] = useState('');
  const [tutors, setTutors] = useState([
    {
      name: 'Malatji Tebalelo',
      subject: 'Math',
      price: 'R430/Month',
      ratings: 4.5,
      image: Rado,
      profileLink: '/tutors/malatji-tebalelo', // Define profile route for each tutor
    },
    {
      name: 'Mamabolo Sedupa',
      subject: 'Physics',
      price: 'R400/Month',
      ratings: 4.8,
      image: Don,
      profileLink: '/tutors/mamabolo-sedupa', // Define profile route for each tutor
    },
    {
      name: 'Modupi Tumelo',
      subject: 'Sepedi',
      price: 'R400/Month',
      ratings: 4.8,
      image: ModupiImage,
      profileLink: '/tutors/modupi-tumelo', // Define profile route for each tutor
    },
    {
      name: 'Modiba Globes',
      subject: 'Geography',
      price: 'R400/Month',
      ratings: 4.8,
      image: Globes,
      profileLink: '/tutors/modiba-globes', // Define profile route for each tutor
    },
    {
      name: 'Emanuel Mawuena',
      subject: 'Math',
      price: 'R430/Month',
      ratings: 4.5,
      image: Mawuena,
      profileLink: '/tutors/emanuel-mawuena', // Define profile route for each tutor
    },
    {
      name: 'Kuda Kembo',
      subject: 'Physics',
      price: 'R400/Month',
      ratings: 4.8,
      image: Kuda,
      profileLink: '/tutors/kuda-kembo', // Define profile route for each tutor
    },
    {
      name: 'Phasha Stellvish',
      subject: 'Sepedi',
      price: 'R400/Month',
      ratings: 4.8,
      image: Stellvish,
      profileLink: '/tutors/phasha-stellvish', // Define profile route for each tutor
    },
    {
      name: 'Monyela Mmaphosa',
      subject: 'Geography',
      price: 'R400/Month',
      ratings: 4.8,
      image: Mmaphosa,
      profileLink: '/tutors/monyela-mmaphosa', // Define profile route for each tutor
    },
  ]);

  const filteredTutors = tutors.filter(tutor =>
    tutor.subject.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSearchInputChange = event => {
    setSearchQuery(event.target.value);
  };

  return (
    <div>
      {/* Search input field */}
      <div className="search-container">
        <input
          type="text"
          placeholder="Search subjects..."
          value={searchQuery}
          onChange={handleSearchInputChange}
          className="search-input"
        />
        <button className="search-btn">
          Search
        </button>
      </div>

      {/* Display filtered tutors */}
      <section className="tutor-section">
        {filteredTutors.map((tutor, index) => (
          <div key={index} className="tutor">
            <img src={tutor.image} alt={tutor.name} />
            <div className="tutor-info">
              <h2>{tutor.name}</h2>
              <p>Subject: {tutor.subject}</p>
              <p>Price: {tutor.price}</p>
              <p>Ratings: {tutor.ratings}</p>
              <Link to={tutor.profileLink} className="profile-btn">
                View Profile
              </Link>
            </div>
          </div>
        ))}
      </section>
    </div>
  );
}

function App() {
  const [sideDrawerActive, setSideDrawerActive] = useState(false);
  const [loadingScreenVisible, setLoadingScreenVisible] = useState(true);
  const [showAbout, setShowAbout] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showFAQ, setShowFAQ] = useState(false);
  const [showTermsOfUse, setShowTermsOfUse] = useState(false);
  const [showOurTeam, setShowOurTeam] = useState(false);
  const [showCareers, setShowCareers] = useState(false);
  const [showUnderConstructionPopup, setShowUnderConstructionPopup] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);

  const handleAppStoreClick = () => {
    setShowUnderConstructionPopup(true);
  };

  const handlePlayStoreClick = () => {
    setShowUnderConstructionPopup(true);
  };

  const handleClosePopup = () => {
    setShowUnderConstructionPopup(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoadingScreenVisible(false);
    }, 1500);
    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

  const handleTouchStart = event => {};
  const handleTouchEnd = event => {};

  const toggleSideDrawer = () => {
    setSideDrawerActive(!sideDrawerActive);
  };

  const toggleAbout = () => {
    setShowAbout(!showAbout);
  };

  const toggleContact = () => {
    setShowContact(!showContact);
  };

  const toggleProfile = () => {
    setShowProfile(!showProfile);
  };

  const toggleFAQ = () => {
    setShowFAQ(!showFAQ);
  };

  const toggleTermsOfUse = () => {
    setShowTermsOfUse(!showTermsOfUse);
  };

  const toggleOurTeam = () => {
    setShowOurTeam(!showOurTeam);
  };

  const toggleCareers = () => {
    setShowCareers(!showCareers);
  };

  const handleLogin = async (credentials) => {
    try {
      // Make API call to Laravel backend to authenticate user
      const response = await axios.post('/api/login', credentials);
      const token = response.data.token;

      // Store token in state or browser storage
      localStorage.setItem('token', token);

      // Set authenticated state to true
      setAuthenticated(true);
    } catch (error) {
      console.error('Login failed', error);
    }
  };

  const handleLogout = () => {
    // Clear token from state or browser storage
    localStorage.removeItem('token');

    // Set authenticated state to false
    setAuthenticated(false);
  };

  return (
    <Router>
      {loadingScreenVisible && <DotLoader />} {/* Use DotLoader here instead of loading.gif */}

      <header className="header">
        <a href="/">
          <img src={myLogo} alt="My Logo" className="app-logo" />
        </a>
        <button className="hamburger-btn" onClick={toggleSideDrawer}>
          ☰
        </button>
      </header>

      <div className={`side-drawer ${sideDrawerActive ? 'active' : ''}`}>
        <nav>
          <ul>
            <li>
              <Link to="/" onClick={toggleSideDrawer}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/my-extra-board" onClick={toggleSideDrawer}>
                Extra Board
              </Link>
            </li>
            <li>
              <Link to="/faq" onClick={toggleSideDrawer}>
                FAQs
              </Link>
            </li>
            <li>
              <Link to="/profile" onClick={toggleSideDrawer}>
                Profile
              </Link>
            </li>
          </ul>
        </nav>
      </div>

      <main className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/my-extra-board" element={<MyExtraBoard />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/student" element={<Student />} />
          <Route path="/support-us" element={<SupportUs />} />
          <Route path="/become-a-tutor" element={<BecomeTutor />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/tutors/malatji-tebalelo" element={<MalatjiProfile />} />
          <Route path="/tutors/mamabolo-sedupa" element={<Mamabolo />} />
          <Route path="/tutors/modupi-tumelo" element={<Modupi />} />
          <Route path="/tutors/modiba-globes" element={<Modiba />} />
          <Route path="/tutors/emanuel-mawuena" element={<Emanuel />} />
          <Route path="/tutors/kuda-kembo" element={<Kuda />} />
          <Route path="/tutors/phasha-stellvish" element={<Phasha />} />
          <Route path="/tutors/monyela-mmaphosa" element={<Monyela />} />
          <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
          <Route path="/dashboard" element={authenticated ? <DashboardPage /> : <Navigate to="/login" />} />
          <Route path="/logout" element={<LogoutPage onLogout={handleLogout} />} />
          

        </Routes>
      </main>

      <footer className="footer footer--scrolled">
        <div className="container">
          <div className="footer-content">
            <div className="footer-section">
              <h3>Company</h3>
              <ul>
                <li>
                  <a href="#" onClick={toggleAbout}>
                    About
                  </a>
                </li>
                <li>
                  <a href="#" onClick={toggleTermsOfUse}>
                    Terms of Use
                  </a>
                </li>
                <li>
                  <a href="#" onClick={toggleOurTeam}>
                    Our Team
                  </a>
                </li>
                <li>
                  <a href="#" onClick={toggleCareers}>
                    Careers
                  </a>
                </li>
                <li>
                  <a href="#" onClick={toggleContact}>
                    Contact
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-section">
              <h3>Resources</h3>
              <ul>
                <li>
                  <Link to="/become-a-tutor">Become a Tutor</Link>
                </li>
                <li>
                  <Link to="/support-us" onClick={() => setSideDrawerActive(false)}>
                    Support Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-section">
              <h3>Download Our App</h3>
              <div className="app-download">
                <img src={appStoreLogo} alt="App Store" onClick={handleAppStoreClick} />
                <img src={playStoreLogo} alt="Play Store" onClick={handlePlayStoreClick} />
              </div>
            </div>
          </div>
          <div className="copyright">
            <p>&copy; 2024 Extra Classes Hub. All rights reserved.</p>
          </div>
        </div>
      </footer>

      {showAbout && <About onClose={toggleAbout} />}
      {showContact && <ContactUs onClose={toggleContact} />}
      {showProfile && <Profile onClose={toggleProfile} />}
      {showFAQ && <FAQ onClose={toggleFAQ} />}
      {showTermsOfUse && <TermsOfUse onClose={toggleTermsOfUse} />}
      {showOurTeam && <OurTeam onClose={toggleOurTeam} />}
      {showCareers && <Careers onClose={toggleCareers} />}
      {showUnderConstructionPopup && <UnderConstructionPopup onClose={handleClosePopup} />}
    </Router>
  );
}

export default App;
