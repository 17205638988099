// TermsOfUse.js

import React from 'react';
import './TermsOfUse.css';

const TermsOfUse = ({ onClose }) => {
  return (
    <div className="terms-overlay" onClick={onClose}>
      <div className="terms-container" onClick={(e) => e.stopPropagation()}>
        <h2>Terms of Use</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non diam
          non leo dignissim ultrices.
        </p>
        <p>
          Phasellus efficitur aliquet odio, id congue est molestie nec. Sed
          vitae ex quis magna ultricies volutpat.
        </p>
        <p>
          Nulla ultricies purus vel ipsum tincidunt, ut sodales turpis
          dignissim. Vestibulum ante ipsum primis in faucibus orci luctus et
          ultrices posuere cubilia Curae; Proin nec dolor est.
        </p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default TermsOfUse;
